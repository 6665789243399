<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
        >
          <b-card-body>

            <validation-observer ref="validationRules">
              <b-form
                @submit.prevent="onSubmit"
                @reset.prevent="resetForm"
              >
                <b-row>
                  <b-col
                    md="12"
                    class="mb-1"
                  >
                    <!-- post video -->
                    <b-embed
                      type="iframe"
                      :src="mediaUrl"
                      allowfullscreen
                      class="rounded mb-50"
                    />
                  </b-col>
                  <!-- Header -->
                  <b-col md="12">
                    <b-form-group
                      :label="$t('STATEMENT')"
                      label-for="id-statement"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('STATEMENT')"
                        rules="required"
                      >
                        <b-form-input
                          id="id-statement"
                          v-model="question.statement"
                          maxlength="100"
                          :placeholder="$t('STATEMENT')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Language -->
                  <b-col
                    md="12"
                  >
                    <b-form-group
                      :label="$t('LANGUAGE')"
                      label-for="id-language"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('LANGUAGE')"
                        rules="required"
                      >

                        <v-select
                          id="id-language"
                          v-model="language"
                          :options="languageOptions"
                          label="name"
                          :clearable="false"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Correct -->
                  <b-col
                    md="6"
                    class="mt-1 mb-1"
                  >

                    <b-form-radio
                      v-model="question.isCorrect"
                      name="correct"
                      value="1"
                    >
                      {{ $t('CORRECT') }}
                    </b-form-radio>
                  </b-col>
                  <!-- Incorrect -->
                  <b-col
                    md="6"
                    class="mt-1 mb-1"
                  >

                    <b-form-radio
                      v-model="question.isCorrect"
                      name="incorrect"
                      value="0"
                    >
                      {{ $t('INCORRECT') }}
                    </b-form-radio>
                  </b-col>
                  <!-- submit and reset -->
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      class="mr-1"
                    >
                      {{ question.questionID ? $t('BUTTON.UPDATE') : $t('BUTTON.ADD') }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      type="reset"
                      variant="outline-secondary"
                    >
                      {{ $t('BUTTON.RESET') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-table
      ref="refQuestionListTable"
      :items="questions"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      class="position-relative"
    >
      <!-- Column:Statement -->
      <template #cell(Statement)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.statement,35) }}
          </span>
        </b-media>
      </template>
      <!-- Column: Language -->
      <template #cell(Language)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ resolveLanguage(data.item.language) }}
          </span>
        </b-media>
      </template>
      <!-- Column:isCorrect -->
      <template #cell(Answer)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.isCorrect === 1 ? 'Correct' : 'Incorrect' }}
          </span>
        </b-media>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.mediaID}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="editQuestion(data.item)"
          />
          <feather-icon
            :id="`game-row-${data.item.mediaID}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer"
            @click="removeQuestion(data.item)"
          />
        </div>
      </template>
    </b-table>
  </section>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardBody, BButton, BForm, BFormGroup,
  BFormInput, BFormRadio, BEmbed, BTable, BMedia,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import constants from '@/constants/static.json'
import { mixinList } from '@/constants/mixinValidations'

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    BEmbed,
    BTable,
    BMedia,
  },
  mixins: [mixinList],
  data() {
    return {
      question: {
        questionID: 0,
        mediaID: 0,
        statement: '',
        isCorrect: 1,
        language: '',
      },
      mediaUrl: '',
      questions: [],
      language: '',
      languageOptions: constants.LANGUAGES,
      required,
    }
  },
  setup() {
    const tableColumns = [
      { key: 'Statement', sortable: false },
      { key: 'Language', sortable: false },
      { key: 'Answer', sortable: false },
      { key: 'Actions' },
    ]

    const {
      successMessage,
      showErrorMessage,
    } = mixinAlert()

    return {
      successMessage,
      showErrorMessage,
      tableColumns,
    }
  },
  created() {
    if (router.currentRoute.params.id) {
      // eslint-disable-next-line radix
      this.question.mediaID = parseInt(router.currentRoute.params.id)
      this.mediaUrl = router.currentRoute.query.url
      if (this.question.mediaID !== 0) {
        this.fetchQuestions()
      }
    }
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters('media', ['getAllQuestions']),
  },
  methods: {
    ...mapActions('media', ['createQuestion', 'updateQuestion', 'deleteQuestion', 'fetchQuestionByMediaID']),
    fetchQuestions() {
      this.fetchQuestionByMediaID(this.question.mediaID).then(() => {
        this.questions = this.getAllQuestions
      })
    },
    editQuestion(data) {
      this.question = { ...data }
      this.language = this.languageOptions.find(i => i.id === this.question.language)
    },
    resetFrom() {
      this.question.questionID = 0
      this.question.statement = ''
      this.question.isCorrect = 1
      this.question.language = ''
      this.language = ''
    },
    resolveLanguage(data) {
      const language = constants.LANGUAGES.find(i => i.id === data)
      if (language) {
        return language.name
      }
      return ''
    },
    removeQuestion(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.statement}  ${this.$i18n.t('QUESTION')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onDeleteQuestion({ questionID: data.questionID })
          }
        })
    },
    onDeleteQuestion(data) {
      if (data) {
        this.deleteQuestion(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.QUESTION_DELETED'))
            this.fetchQuestions()
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    onSubmit() {
      this.$refs.validationRules.validate().then(success => {
        if (success) {
          this.question.language = this.language.id
          if (this.question.questionID) {
            this.updateQuestion(this.question).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.QUESTION_UPDATED'))
                this.resetFrom()
                this.fetchQuestions()
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          } else {
            this.createQuestion(this.question).then(response => {
              if (response) {
                this.successMessage(this.$i18n.t('MESSAGE.QUESTION_CREATED'))
                this.resetFrom()
                this.fetchQuestions()
              }
            }).catch(() => {
              this.showErrorMessage()
            })
          }
        }
      })
    },
  },

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }

}
</style>
